import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import { LightgalleryItem } from 'react-lightgallery';
import img1_1 from '../../assets/images/szepasszony-volgy/1.jpg';
import img1_2 from '../../assets/images/szepasszony-volgy/2.jpg'; // portrait
import img1_3 from '../../assets/images/szepasszony-volgy/3.jpg';
import img1_4 from '../../assets/images/szepasszony-volgy/4.jpg';
import img1_5 from '../../assets/images/szepasszony-volgy/5.jpg';
import img1_6 from '../../assets/images/szepasszony-volgy/6.jpg';
import img1_7 from '../../assets/images/szepasszony-volgy/7.jpg'; // portrait
import img1_8 from '../../assets/images/szepasszony-volgy/8.jpg';
import img1_9 from '../../assets/images/szepasszony-volgy/9.jpg';
import img1_10 from '../../assets/images/szepasszony-volgy/10.jpg';
import img1_11 from '../../assets/images/szepasszony-volgy/11.jpg';
import img1_12 from '../../assets/images/szepasszony-volgy/12.jpg';

import img2_1 from '../../assets/images/szepasszony-volgy/13.jpg';
import img2_2 from '../../assets/images/szepasszony-volgy/14.jpg';
import img2_3 from '../../assets/images/szepasszony-volgy/15.jpg';
import img2_4 from '../../assets/images/szepasszony-volgy/16.jpg';
import img2_5 from '../../assets/images/szepasszony-volgy/17.jpg';
import img2_6 from '../../assets/images/szepasszony-volgy/18.jpg';
import img2_7 from '../../assets/images/szepasszony-volgy/19.jpg';
import img2_8 from '../../assets/images/szepasszony-volgy/20.jpg';
import img2_9 from '../../assets/images/szepasszony-volgy/21.jpg';
import img2_10 from '../../assets/images/szepasszony-volgy/22.jpg';
import img2_11 from '../../assets/images/szepasszony-volgy/23.jpg';
import img2_12 from '../../assets/images/szepasszony-volgy/24.jpg';

const BemutatoteremBudapest = () => (
  <Layout page="szepasszony-volgy">
    <article id="main">
      <header>
        <h2>WINE BAR&SHOP – SZÉPASSZONY-VÖLGY</h2>
        <p>
          BOR BÁRUNK KELLEMES FELFRISSÜLÉST BIZTOSÍT A SZÉPASSZONY-VÖLGYBE
          LÁTOGATÓKNAK KLASSZIKUS ÉS PRÉMIUM BORAINKKAL.
        </p>
      </header>
    </article>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>A WINE BAR & SHOPRÓL</h2>
          <p>
            2022. júliustól az egri pincevárosban, a Szépasszony-völgyben is
            megtalálhatóak vagyunk „Juhász Testvérek Pincészete Wine Bar & Shop”
            néven. A jól ismert tételek mellett szeretnénk bemutatni teljes
            portfóliónkat, prémium tételeinket és még többet a dűlőinkről,
            borvidékünkről.
          </p>

          <p>
            Boraink mellé, kézműves, helyi sajtokból készült sajttálakkal,
            szarvaspástétommal és frissensült bagettel várunk mindenkit.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
      <div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_1}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/1.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              {/* <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_2}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/2.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div> */}

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_3}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/3.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_4}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/4.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_5}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/5.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_6}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/6.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              {/* <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_7}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/7.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div> */}

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_8}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/8.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_9}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/9.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_10}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/10.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_11}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/11.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img1_12}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/12.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>ÍZELÍTŐ EGY BORKÓSTOLÓBÓL</h2>
          <p>
            A jól ismert Szépasszony-völgy egy ikonikus hely egy egri
            borkóstolóhoz.
          </p>
          <p>
            A Juhász Wine Bar borkóstoló menete szerint egy könnyed pezsgővel
            nyitjuk meg a négy tételes borkóstolót. Ezt követően vendégeink
            megkóstolnak egy Egri Csillagot, amely egy egyre népszerűbb fehér
            egri házasítás, amit az Egri Bikavér fehér párjának szántunk.
            Gyümölcsös, zamatos fehérbor, amely kötelező eleme minden egri
            borkóstolónak. Természetesen, ha nálunk jár, akkor nem érdemes
            kihagyni, hogy megkóstolon egy Juhász rosét de az alap tartalmaz egy
            másik nagy közönségkedvencet, hisz a kóstolócsomagban egy syrah-ból
            készült rosé is van. Zárásként, családunk legértékesebb dűlőiről
            származó vörös bikavér házasítást mutatjuk be. Az Egri Bikavér
            Premium Selection már-már fekete színű, elképesztő beltartalommal,
            íz- és illatjegyekkel bíró vörösbor, melyet egész biztosan imádni
            fog.
          </p>

          <p>
            A borkóstoló mellé érdemes borkorcsolyát is kérni, ami sok minden
            lehet, ezért ezt érdemes előre egyeztetni bárunk személyzetével.
            Többek között füstölt padlizsánkrém, magvas dijoni mustárral,
            vörösboros-áfonyás szarvaspástétom, vagy pedig szilvásváradi
            capris-citrusos pisztráng krém opciók közül választhatnak
            vendégeink, amelyekhez frissen sült bagett is jár. Ha valami
            laktatóbbra vágynak, akkor pedig egy kiadós hidegtálat is
            rendelhettek a Juhász Testvérek Pincészete Wine Bar
            szépasszony-völgyi borkóstolója mellé Egerben.
          </p>

          <p>
            Cím: Eger, Szépasszony-völgy 38.
            <br />
            Telefonszám +36 20 396 21 83
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
      <div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_1}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/13.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_2}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/14.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_3}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/15.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_4}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/16.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_5}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/17.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_6}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/18.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_7}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/19.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_8}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/20.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_9}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/21.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_10}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/22.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_11}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/23.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2_12}>
                    <StaticImage
                      src="../../assets/images/szepasszony-volgy/24.jpg"
                      alt=""
                    />
                  </LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </Layout>
);

export default BemutatoteremBudapest;
